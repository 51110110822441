import { createSlice } from '@reduxjs/toolkit'
import { addStoreSetting, deleteWebsite, editStoreSetting, listWebsiteStore, singleWebsiteView } from '../../service/settingsService/storeSettingsService'


const initialState = {
  addStoreSettingRes: { data: [], isFetching: false, error: null },
  editStoreSettingRes: { data: [], isFetching: false, error: null },
  listWebsiteStoreRes: { data: [], isFetching: false, error: null },
  singleWebsiteViewRes: { data: [], isFetching: false, error: null },
  deleteWebsiteViewRes: { data: [], isFetching: false, error: null },
}

export const storeSettingsSlice = createSlice({
  name: 'storeSettingsSlice',
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: addStoreSetting, name: "addStoreSettingRes" },
      { api: deleteWebsite, name: "deleteWebsiteViewRes" },
      { api: editStoreSetting, name: "editStoreSettingRes" },
      { api: listWebsiteStore, name: "listWebsiteStoreRes" },
      { api: singleWebsiteView, name: "singleWebsiteViewRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { storeSettingsDispatch } = storeSettingsSlice.actions
