import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// Store Create
export const addStoreSetting = createAsyncThunk(
  'add-store-settings',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/website`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })


// Store Website List 
export const listWebsiteStore = createAsyncThunk(
  'list-store-settings',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/websites`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// view single Website
export const singleWebsiteView = createAsyncThunk(
  'single-store-settings',
  async (params, thunkAPI) => {
    try {
      const response = await API.get(`/websites/${params}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Edit Store
export const editStoreSetting = createAsyncThunk(
  'edit-store-settings',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/website/${bodyParams.id}`, bodyParams.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Delete Store
export const deleteWebsite = createAsyncThunk(
  'delete-store-settings',
  async (params, thunkAPI) => {
    try {
      const response = await API.put(`website/${params}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })





